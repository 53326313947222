import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import Layout from '../components/layout'
import SEO from '../components/seo'

const PageTitle = styled.h1`
  ${tw`text-center`}
`

const ColumnWrap = styled.div`
  ${tw`md:flex flex-wrap mx-auto text-center`}
  max-width: 900px;
  p,
  h3 {
    font-size: 0.85rem;
    line-height: 1.75;
  }
  h3 {
    ${tw`mt-5`}
  }
  h2 + p {
    ${tw`mt-2`}
  }
`

const Column = styled.div`
  ${tw`md:w-1/2 px-10`}
`

const ResumePage = () => {
  const data = useStaticQuery(graphql`
    query ResumeQuery {
      prismicResume {
        data {
          column_1 {
            html
          }
          column_2 {
            html
          }
        }
      }
    }
  `)
  // eslint-disable-next-line camelcase
  const { column_1, column_2 } = data.prismicResume.data
  return (
    <Layout>
      <SEO title="Resume" />
      <PageTitle>Resume</PageTitle>
      <ColumnWrap>
        <Column dangerouslySetInnerHTML={{ __html: column_1.html }} />
        <Column dangerouslySetInnerHTML={{ __html: column_2.html }} />
      </ColumnWrap>
    </Layout>
  )
}

export default ResumePage
